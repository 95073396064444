import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Header from './components/Header'
import Content from './components/Content'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import Logo from './images/logo.png'
// import GA from './utils/GoogleAnalytics'

window.addEventListener('scroll', () => {
  const toTop = document.getElementById('toTopButton')
  if (window.pageYOffset >= 500) {
    toTop.style.display = 'block'
  } else {
    toTop.style.display = 'none'
  }
})

const App = () => {
  return (
    <div className="App">
      <div className="loading">
        <img src={Logo} alt="LOGO" width="300" />
      </div>
      <div className="AppInner">
        <CssBaseline />
        <Router>
          {/* {GA.init() && <GA.RouteTracker />} */}
          <ScrollToTop>
            <Header />
            <Route
              path="/greeting"
              render={() => <Content page="館長ご挨拶" />}
            />
            <Route
              path="/museuminfo"
              render={() => <Content page="館内ご案内" />}
            />
            <Route
              path="/museumshop"
              render={() => <Content page="Museum Shop" />}
            />
            <Route
              path="/exhibition"
              render={() => <Content page="展示室" />}
            />
            <Route
              path="/specialexhibition"
              render={() => <Content page="特別展示室" />}
            />
            <Route
              path="/restroom"
              render={() => <Content page="お化粧室" />}
            />
            <Route path="/link" render={() => <Content page="Link" />} />
            <Route exact path="/" render={() => <Content page="HOME" />} />
            <Footer />
            <div
              id="toTopButton"
              className="toTopButton"
              onClick={() => {
                window.scrollTo(0, 0)
              }}
            >
              <ExpandLessIcon style={{ color: '#EB6200' }} />
            </div>
          </ScrollToTop>
        </Router>
      </div>
    </div>
  )
}

export default App
