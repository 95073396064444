/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import UpdateIcon from '@material-ui/icons/Update'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CircularProgress from '@material-ui/core/CircularProgress'
import SlickSlider from '../components/SpecialSlider'
import GLOBAL from '../Global'

class ContentsSingle extends Component {
  setTitle = title => {
    document.title = `${title} | ${GLOBAL.SITENAME}`
  }

  render() {
    const {
      props: { category, data, history },
    } = this
    if (!data)
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    this.setTitle(data.title)

    let info = ''
    if (data.category.includes('お知らせ')) {
      info = 'info'
    }

    return (
      <div className={`single ${category}`}>
        <Button
          variant="contained"
          style={{ background: '#EB6200', color: 'white', margin: '0 0 10px' }}
          onClick={() => history.goBack()}
          className="backButton"
        >
          <ArrowBackIosIcon />
          BACK
        </Button>
        <div className="mainHeading">
          <h1>{data.title}</h1>
        </div>
        <div className="mainContent">
          <div className="mainContainer">
            <div className="mainBody">
              <div className="tagContainer">
                {data.category.map((e, i) => {
                  return (
                    <div className={`tag ${info}`} key={i}>
                      {e}
                    </div>
                  )
                })}
              </div>
              {data.category.includes('お知らせ') && (
                <div className="dateCreated">
                  <UpdateIcon /> {data.date} UPDATED
                </div>
              )}
              {data.imageUrl && (
                <div className="mainVisual">
                  <SlickSlider
                    images={data.imageUrl}
                    category="tour"
                    maxWidth="600"
                    captions={data.caption}
                  />
                </div>
              )}
              <div
                className="textBox"
                dangerouslySetInnerHTML={{ __html: data.text }}
              />
              <div
                className="freeHtml"
                dangerouslySetInnerHTML={{ __html: data.freeHtml }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ContentsSingle)

ContentsSingle.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
  history: PropTypes.object.isRequired,
}

ContentsSingle.defaultProps = {
  data: null,
  category: null,
}
