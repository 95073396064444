import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const MyButton = styled(Button)({
  // background: 'linear-gradient(45deg, #4343C8 30%, #00008B 90%)',
  background: '#EB6200',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(146,146,146, .3)',
  color: 'white',
  maxWidth: 300,
  height: 48,
  margin: '20px auto',
  padding: '0 30px',
  display: 'block',
  '&:hover': {
    background: '#F3AB00',
  },
})

export default function StyledComponents(props) {
  const { link, text, target } = props
  if (target) {
    return (
      <MyButton className="myButton">
        <a
          className="buttonLinkText"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      </MyButton>
    )
  }
  return (
    <MyButton className="myButton">
      <a className="buttonLinkText" href={link} >
        {text}
      </a>
    </MyButton>
  )
}

StyledComponents.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
}

StyledComponents.defaultProps = {
  link: null,
  target: false,
}
