/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import GLOBAL from '../Global'

let keepState = ''
let keepPage = ''
let keepData = ''
let keepCategory = []

const END_POINT = '/data/category.json'

class ContentsList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      category: [],
      current: keepState !== '' ? keepState : 'all',
      page: keepPage !== '' ? keepPage : 1,
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ category: result.data })
    })
    if (keepData.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
      this.setState({
        page: 1,
      })
      keepPage = ''
    }
    if (keepState !== '') {
      const category = {}
      category.slug = keepState
      let categoryName
      keepCategory.forEach(e => {
        const slug = e.slug
        if (slug === keepState) {
          categoryName = e.name
        }
      })
      category.name = categoryName
      this.category(category, keepData)
    }
    if (keepPage !== '') {
      this.setState({
        page: keepPage,
      })
    }
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      page: 1,
    })
  }

  category(e, data) {
    this.stateInitialize()
    if (e.slug === 'all') {
      this.setState({ stateData: data })
    } else {
      const category = data.filter(el => el.category.includes(e.name))
      this.setState({ stateData: category, current: e.slug })
    }
  }

  handlePage(e, value) {
    this.setState({ page: value })
    window.scrollTo(0, 0)
    keepPage = value
  }

  render() {
    const { stateData, current, page, category } = this.state

    keepState = current

    const { data, max } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    const allData = _.orderBy(selectedData, ['generalId'], ['desc'])
    let reverseData = allData.filter(e => e !== undefined || e !== null)

    const dataLength = reverseData.length
    let perPage = 20
    if (max) perPage = max
    let pageNumber = 1
    if (dataLength > perPage) {
      pageNumber = parseInt(dataLength / perPage, 10) + 1
      reverseData = reverseData.slice(perPage * (page - 1), perPage * page)
    }
    if (data.length === 0 || category.length === 0) {
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    }

    keepData = data
    keepCategory = category

    // const category = ['all', 'category01', 'category02', 'category03', 'info']

    return (
      <>
        {!max && (
          <div className="tourCategory">
            {category.map(e => {
              return (
                <Button
                  key={e.slug}
                  disabled={
                    e.slug !== 'all' &&
                    data.filter(el => el.category.includes(e.name)).length === 0
                  }
                  className={current === e.slug ? 'current' : ''}
                  variant="contained"
                  onClick={() => {
                    this.category(e, data)
                  }}
                >
                  {e.name}
                </Button>
              )
            })}
          </div>
        )}
        <div className="mainContainer">
          <div className="mainBody">
            {current !== 'all' && (
              <div className="exhibitionTitle">
                <h2 className="heading01">
                  <AccountBalanceIcon />
                  {GLOBAL.EXHIBITION[current].TITLE}
                </h2>
                <p>{GLOBAL.EXHIBITION[current].TEXT}</p>
              </div>
            )}
            <ul className="list archive">
              {reverseData.map(e => {
                const link = `/exhibition/${e.slug}`
                // const tag = e.tag
                let thumbNail
                if (e.thumbnailUrl === '') {
                  thumbNail = `/images/exhibition/${e.imageUrl[0]}`
                } else {
                  thumbNail = `/images/exhibition/${e.thumbnailUrl}`
                }
                return (
                  <Link to={link} key={e.slug} className="listPart">
                    {/* <span className={`tag ${e.brandCategory[0]}`}>{tag}</span> */}
                    <li>
                      <div className="thumbnail">
                        <img src={thumbNail} alt={e.title} />
                      </div>
                      <div className="titleBox">
                        {e.category.includes('お知らせ') && (
                          <span className="date">{e.date}</span>
                        )}
                        <div
                          className="titleBoxTitle"
                          dangerouslySetInnerHTML={{ __html: e.title }}
                        />
                      </div>
                    </li>
                  </Link>
                )
              })}
            </ul>
            {!max && (
              <Pagination
                count={pageNumber}
                className="pagination"
                page={page}
                onChange={(e, value) => {
                  this.handlePage(e, value)
                }}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(ContentsList)

ContentsList.propTypes = {
  data: PropTypes.array,
}

ContentsList.defaultProps = {
  data: null,
}
