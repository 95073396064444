/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'
import restroom01 from '../images/restroom01.jpg'
import restroom02 from '../images/restroom02.jpg'

const Restroom = () => {
  return (
    <>
      <div className="mainHeading">
        <h1>{GLOBAL.RESTROOM.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div
          className="textBox"
          dangerouslySetInnerHTML={{ __html: GLOBAL.RESTROOM.TEXT }}
        />
        <div className="textBox">
          <img src={restroom01} alt="" />
          <p>Corgiville Museum トイレミニチュア製作<br />紅木　美る</p>
          <img src={restroom02} alt="" />
          <p>きれいに使っていただいてありがとうございます。</p>
        </div>
      </div>
    </>
  )
}

export default withRouter(Restroom)
