import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'

class BreadCrumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data2: [],
    }
  }

  componentDidMount() {
    const endPoint = '/data/exhibition.json'
    const endPoint2 = '/data/specialexhibition.json'
    // endPoint = 'https://mk-official-data.firebaseio.com/contents.json'
    axios.get(endPoint).then(result => {
      this.setState({ data: result.data })
    })
    axios.get(endPoint2).then(result => {
      this.setState({ data2: result.data })
    })
  }

  render() {
    const {
      state: { data, data2 },
      props: { location },
    } = this
    const splitPath = location.pathname.split('/').filter(e => e !== '')
    return (
      <Breadcrumbs
        className="breadCrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/">
          HOME
        </Link>
        {splitPath.map((element, i) => {
          if (i + 1 >= splitPath.length) {
            if (i === 0) {
              let categoryName = element.toUpperCase()
              if (categoryName === 'GREETING') {
                categoryName = '館長ご挨拶'
              } else if (categoryName === 'MUSEUMINFO') {
                categoryName = '館内ご案内'
              } else if (categoryName === 'MUSEUMSHOP') {
                categoryName = 'Museum Shop'
              } else if (categoryName === 'EXHIBITION') {
                categoryName = '展示室'
              } else if (categoryName === 'SPECIALEXHIBITION') {
                categoryName = '特別展示室'
              } else if (categoryName === 'RESTROOM') {
                categoryName = 'お化粧室'
              } else if (categoryName === 'Link') {
                categoryName = 'Link'
              }
              return (
                <Typography key={element} color="textPrimary">
                  {categoryName}
                </Typography>
              )
            }
            const matchData = Array.from(data).filter(e => e.slug === element)
            if (matchData[0]) {
              return (
                <div key={element}>
                  <Typography
                    key={element}
                    color="textPrimary"
                    dangerouslySetInnerHTML={{ __html: matchData[0].title }}
                  />
                </div>
              )
            }
            const matchData2 = Array.from(data2).filter(e => e.slug === element)
            if (matchData[0]) {
              return (
                <div key={element}>
                  <Typography key={element} color="textPrimary">
                    {matchData2[0].title}
                  </Typography>
                </div>
              )
            }
            return null
          }
          let categoryName = element.toUpperCase()
          if (categoryName === 'EXHIBITION') {
            categoryName = '展示室'
          } else if (categoryName === 'SPECIALEXHIBITION') {
            categoryName = '特別展示室'
          }
          return (
            <Link key={element} color="inherit" href={`/${element}`}>
              {categoryName}
            </Link>
          )
        })}
      </Breadcrumbs>
    )
  }
}

export default withRouter(BreadCrumb)

BreadCrumb.propTypes = {
  category: PropTypes.string,
  location: PropTypes.object,
}
BreadCrumb.defaultProps = {
  category: null,
  location: null,
}
