import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Greeting from '../containers/Greeting'
import Museuminfo from '../containers/Museuminfo'
import Museumshop from '../containers/Museumshop'
import Link from '../containers/Link'
import Exhibition from '../containers/Exhibition'
import Restroom from '../containers/Restroom'
import NotFound from '../containers/NotFound'
import BreadCrumb from './BreadCrumb'
import GLOBAL from '../Global'
import SpecialExhibition from '../containers/SpecialExhibition'

class Main extends Component {
  constructor(props) {
    super()
    this.props = props
    this.page = props.page
    this.path = props.location.pathname.replace(/\//g, '') // ここで現在のパスを取得
  }

  componentDidMount() {
    const { page } = this.props
    if (page === 'PRODUCT') {
      document.title = GLOBAL.SITENAME
    } else {
      document.title = `${page} | ${GLOBAL.SITENAME}`
    }
  }

  render() {
    const { match } = this.props
    const path = match.path.replace(/\//g, '')
    let pageContent
    if (path === 'exhibition') {
      pageContent = <Exhibition />
    } else if (path === 'specialexhibition') {
      pageContent = <SpecialExhibition />
    } else if (path === 'greeting') {
      pageContent = <Greeting />
    } else if (path === 'museuminfo') {
      pageContent = <Museuminfo />
    } else if (path === 'museumshop') {
      pageContent = <Museumshop />
    } else if (path === 'link') {
      pageContent = <Link />
    } else if (path === 'restroom') {
      pageContent = <Restroom />
    } else {
      pageContent = <NotFound />
    }

    if (path === '') {
      return <div className="mainInner">{pageContent}</div>
    }
    return (
      <main className={path}>
        <BreadCrumb category={path} />
        <div className="mainInner">{pageContent}</div>
      </main>
    )
  }
}

export default withRouter(Main)

Main.propTypes = {
  page: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
}

Main.defaultProps = {
  page: null,
  location: null,
  match: null,
}
