/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import InfoIcon from '@material-ui/icons/Info'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import LinkIcon from '@material-ui/icons/Link'
import WcIcon from '@material-ui/icons/Wc'
import BookIcon from '@material-ui/icons/Book'
import StorefrontIcon from '@material-ui/icons/Storefront'
import InstagramIcon from '@material-ui/icons/Instagram'
import Contents from './Exhibition'
import GLOBAL from '../Global'
import MyButton from '../modules/MyButton'
import bannerRestroom from '../images/banner_restroom.jpg'
import bannerLink from '../images/banner_link.jpg'
import corgivilleblog from '../images/corgivilleblog.png'
import nonnagiglio from '../images/nonnagiglio.png'
import instagram from '../images/instagram.png'
import bannerFeygirl from '../images/banner_feygirl.png'
import bannerFeygirlSp from '../images/banner_feygirl_sp.png'
import mainVisual from '../images/mainVisual.png'
import HomeContentBox from '../modules/HomeContentBox'
import '../css/Home.css'

const END_POINT = '/data/topics.json'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: [],
    }
  }

  componentDidMount() {
    document.title = GLOBAL.SITENAME
    axios.get(END_POINT).then(result => {
      const host = window.location.hostname
      let data
      if (host === 'localhost') {
        data = result.data.filter(e => e !== null)
      } else {
        data = result.data.filter(e => e !== null && e.show !== 'hidden')
      }
      this.setState({ topics: data })
    })
  }

  render() {
    const {
      state: { topics },
    } = this

    // HOME出力用
    return (
      <main className="home">
        <div className="mainInner">
          <h1 className="mainVisual">
            <img src={mainVisual} alt="" />
            {/* {GLOBAL.HOME.MAINVISUAL} */}
            <span dangerouslySetInnerHTML={{ __html: GLOBAL.HOME.SUBTEXT }} />
          </h1>
          <h2 className="heading01">
            <InfoIcon />
            {GLOBAL.HOME.TITLE01}
          </h2>
          <div className="topicsBox">
            <ul>
              {topics.reverse().map((e, i) => {
                return (
                  <li key={i}>
                    <span className="topicsDate">{e.date}</span>
                    <span dangerouslySetInnerHTML={{ __html: e.text }} />
                  </li>
                )
              })}
            </ul>
          </div>
          <h2 className="heading01">
            <AccountBalanceIcon />
            {GLOBAL.HOME.TITLE02}
          </h2>
          <div className="mainContainer">
            <div className="exhibition">
              <Contents max="8" />
              <MyButton link="/exhibition" text={GLOBAL.HOME.MORE} />
            </div>
          </div>
          <div className="bannerBox">
            <a href="/specialexhibition">
              <img src={bannerFeygirl} className="bannerPc" alt="" />
              <img src={bannerFeygirlSp} className="bannerSp" alt="" />
            </a>
          </div>
          <div className="homeContentBox">
            <HomeContentBox
              icon={<LinkIcon />}
              title="LINK"
              link="/link"
              src={bannerLink}
            />
            <HomeContentBox
              icon={<WcIcon />}
              title="Rest room"
              link="/restroom"
              src={bannerRestroom}
            />
            <HomeContentBox
              icon={<BookIcon />}
              title="獅子座のケンタウロス"
              link="https://plaza.rakuten.co.jp/corgiville/"
              src={corgivilleblog}
              target="blank"
            />
            <HomeContentBox
              icon={<InstagramIcon />}
              title="Instagram"
              link="https://www.instagram.com/corgi.ville23/"
              src={instagram}
              target="blank"
            />
            {/* <HomeContentBox
              icon={<StorefrontIcon />}
              title="Nonna Giglio"
              link="https://nonna-giglio.com"
              src={nonnagiglio}
              target="blank"
            /> */}
          </div>
          <div className="textBox">
            <iframe style={{width: '120px', height:'240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=httpwwwcorgic-22&m=amazon&o=9&p=8&l=as1&IS1=1&detail=1&asins=4577046512&linkId=6469001a4572369ab0eca922ac780edb&bc1=ffffff&lt1=_top&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" title="1"></iframe>
            <iframe style={{width: '120px', height:'240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=httpwwwcorgic-22&m=amazon&o=9&p=8&l=as1&IS1=1&detail=1&asins=4046002603&linkId=c128d780ef736e3f30d0c4e69334d3c2&bc1=ffffff&lt1=_top&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" title="2"></iframe>
            <iframe style={{width: '120px', height:'240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=httpwwwcorgic-22&m=amazon&o=9&p=8&l=as1&IS1=1&detail=1&asins=B07CGB7SYF&linkId=6fc0c92682d186b81b9158f91f4f9aea&bc1=ffffff&lt1=_top&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" title="3"></iframe>
            <iframe style={{width: '120px', height:'240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=httpwwwcorgic-22&m=amazon&o=9&p=8&l=as1&IS1=1&detail=1&asins=B0893SV4VC&linkId=35217d4555d09fcdc14add4efd4cfa45&bc1=ffffff&lt1=_top&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" title="4"></iframe>
            <iframe style={{width: '120px', height:'240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=httpwwwcorgic-22&m=amazon&o=9&p=8&l=as1&IS1=1&detail=1&asins=B084H315CB&linkId=2429db6fbd4b2093f41af96dab3797a1&bc1=ffffff&lt1=_top&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" title="5"></iframe>
          </div>
          {/* <MyButton link="/museumshop" text={GLOBAL.HOME.SHOP} /> */}
        </div>
      </main>
    )
  }
}

export default withRouter(Home)
