/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import SpecialExhibitionList from './SpecialExhibitionList'
import Single from '../components/Single'
import GLOBAL from '../Global'
import '../css/ScheduleTour.css'
import '../css/Exhibition.css'

// const END_POINT ='https://mk-official-data.firebaseio.com/contents.json'
const END_POINT = '/data/specialexhibition.json'

class Contents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      const host = window.location.hostname
      let data
      if (host === 'localhost') {
        data = result.data.filter(e => e !== null)
      } else {
        data = result.data.filter(e => e !== null && e.show !== 'hidden')
      }
      this.setState({ topics: data })
    })
  }

  render() {
    const {
      props: { history, max },
      state: { topics },
    } = this
    const path = history.location.pathname // 現在のパス
    if (path === '/specialexhibition/' || path === '/specialexhibition') {
      return (
        <div>
          <div className="mainHeading onlyEnglish">
            <h1>{GLOBAL.SPECIALEXHIBITION.TITLE}</h1>
          </div>
          <SpecialExhibitionList data={topics} />
        </div>
      )
    }
    if (path === '/') {
      // HOME出力用
      return (
        <div>
          <SpecialExhibitionList max={max} data={topics} />
        </div>
      )
    }
    return (
      <div>
        <Single category="specialexhibition" data={topics} path={path} />
      </div>
    )
  }
}

export default withRouter(Contents)

Contents.propTypes = {
  history: PropTypes.object,
}

Contents.defaultProps = {
  history: null,
}
