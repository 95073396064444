/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'

const Link = () => {
  return (
    <>
      <div className="mainHeading">
        <h1>{GLOBAL.LINK.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div
          className="textBox"
          dangerouslySetInnerHTML={{ __html: GLOBAL.LINK.TEXT }}
        />
        <ul className="topicsBox">
          <li><a href="http://www.maedafamily.com/index2.htm" target="_blank" rel="noopener noreferrer"><span className="topicsDate">JFK倶楽部</span></a>JFK倶楽部公式サイト</li>
          <li><a href="https://kennedy.exblog.jp/" target="_blank" rel="noopener noreferrer"><span className="topicsDate">ケネディの会</span></a>ケネディの会のページ</li>
          <li><a href="https://www.jfk.org/" target="_blank" rel="noopener noreferrer"><span className="topicsDate">The Sixth Floor Museum</span></a>米・ダラスにあるJFKミュージアム</li>
          <li><a href="https://www.jfklibrary.org/" target="_blank" rel="noopener noreferrer"><span className="topicsDate">John F. Kennedy Presidential Library &amp; Museum</span></a>米・ボストンにあるJFKライブラリー</li>
          <li><a href="https://jfkhyannismuseum.org/" target="_blank" rel="noopener noreferrer"><span className="topicsDate">John F. Kennedy Hyannis Museum</span></a>米・ハイアニスにあるJFKミュージアム</li>
        </ul>
      </div>
    </>
  )
}

export default withRouter(Link)
