/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import TheatersIcon from '@material-ui/icons/Theaters'
import AlbumIcon from '@material-ui/icons/Album'
import GLOBAL from '../Global'
import cm from '../images/cm.png'
import greeting01 from '../images/greeting01.jpg'
import greeting02 from '../images/greeting02.jpg'
import MyButton from '../modules/MyButton'

const Greeting = () => {
  return (
    <>
      <div className="mainHeading">
        <h1>{GLOBAL.GREETING.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div className="mainVisual">
          <img src={cm} alt="" />
        </div>
        <div
          className="textBox"
          dangerouslySetInnerHTML={{ __html: GLOBAL.GREETING.TEXT }}
        />
        <div className="imageBox">
          <img src={greeting01} alt="" />
          <div dangerouslySetInnerHTML={{ __html: GLOBAL.GREETING.TEXT2 }} />
        </div>
        <div className="textBox">
          <h2 className="heading01">
            <AlbumIcon />
            {GLOBAL.GREETING.TITLE2}
          </h2>
        </div>
        <div className="imageBox">
          <img src={greeting02} alt="" />
          <div dangerouslySetInnerHTML={{ __html: GLOBAL.GREETING.TEXT3 }} />
        </div>
        <div className="textBox">
          <h2 className="heading01">
            <TheatersIcon />
            {GLOBAL.GREETING.TITLE3}
          </h2>
        </div>
        <ul
          className="topicsBox"
          dangerouslySetInnerHTML={{ __html: GLOBAL.GREETING.TEXT4 }}
        />
        <MyButton
          link="https://form.corgivillemuseum.com"
          target="_blank"
          text={GLOBAL.CONTACT.TEXT}
        />
      </div>
    </>
  )
}

export default withRouter(Greeting)
