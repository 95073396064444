/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import GLOBAL from '../Global'
import MyButton from '../modules/MyButton'

const Museuminfo = () => {
  return (
    <>
      <div className="mainHeading">
        <h1>{GLOBAL.MUSEUMINFO.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div className="textBox">
          <h2 className="heading01">
            <AccountBalanceIcon />
            {GLOBAL.MUSEUMINFO.TEXT}
          </h2>
        </div>

        <ul className="topicsBox">
          <li><span className="topicsDate">第1展示室</span>JFKの胸像：大小さまざまな胸像コレクション</li>
          <li><span className="topicsDate">第2展示室</span>ロッキングチェァと大統領：愛用のロッキングチェアーの人形</li>
          <li><span className="topicsDate">第3展示室</span>JFKのレコード・ビデオ・コイン・ポスター・パンフレット</li>
          <li><span className="topicsDate">第4展示室</span>トランプ・ボードゲーム・GIジョー・ジグゾーパズル</li>
          <li><span className="topicsDate">第5展示室</span>絵皿・タピストリー・タイル・ティータオル</li>
          <li><span className="topicsDate">第6展示室</span>キャンペーングッズ・就任記念グッズ</li>
          <li><span className="topicsDate">第7展示室</span>肖像画・レリーフ・フィギュア</li>
          <li><span className="topicsDate">第8展示室</span>Tシャツ・ライター・ペンダント・懐中時計・スカーフ・グラス</li>
          <li><span className="topicsDate">第9展示室</span>ジャッキー・ワールド：ジャクリーン夫人の人形、グッズ</li>
          <li><span className="topicsDate">第10展示室</span>メダル・豆本・紙幣・軍票・キーホルダー</li>
          <li><span className="topicsDate">第11展示室</span>世界のケネディマニアからバトンタッチしたFDC・切手コレクション</li>
          <li><span className="topicsDate">第12展示室</span>ジャックとジャッキーの２ショットアイテム</li>
        </ul>
        <MyButton link="/exhibition" text={GLOBAL.HOME.MORE} />
        <ul className="topicsBox">
          <li><a href="/museumshop"><span className="topicsDate">Museum Shop</span></a>amazonのケネディ関連の書籍の販売。当館オリジナルグッズも計画中</li>
          <li><a href="/link"><span className="topicsDate">Link</span></a>ケネディに関連するリンク集です</li>
          <li><a href="/restroom"><span className="topicsDate">お化粧室</span></a>お化粧室はミュージアムショップの奥にございます</li>
        </ul>

        <MyButton
          link="https://form.corgivillemuseum.com"
          target="_blank"
          text={GLOBAL.CONTACT.TEXT}
        />
      </div>
    </>
  )
}

export default withRouter(Museuminfo)
